import { Platform } from '@ionic/angular';
import { environment } from "environments/environment";
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { AuthService } from 'app/core/auth/auth.service';
import { EventEmitter, Injectable, Output } from "@angular/core";
import { Router } from "@angular/router";
declare global {
  const google: typeof import('google-one-tap');
}
@Injectable({
  providedIn: 'root',
})
export class GoogleAuth {
  ngZone: any;
  @Output() loginWithGoogle: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private platform: Platform,
    private _router: Router,
    private authService: AuthService,
  ) { }
  async oneTap() {
    if (!this.platform.is('android') && !this.platform.is('ios') && !this.platform.is('capacitor')) {
      const SA_ID = { 'key': 'ID_GOOGLE' };
      SecureStoragePlugin.get(SA_ID)
        .then().catch((err) => {
          this.initGoogleWebAuth()
        })
    }
  }
  initGoogleWebAuth() {
    // @ts-ignore
    window.onGoogleLibraryLoad = () => {
      google.accounts.id.initialize({
        client_id: environment.oauth.google,
        callback: this.handleCredentialResponse.bind(this), // Whatever function you want to trigger...
        auto_select: false,
        cancel_on_tap_outside: true
      });
      // @ts-ignore
      google.accounts.id.prompt((notification: PromptMomentNotification) => {
        // console.log('Google prompt event triggered...');
        if (notification.getDismissedReason() === 'credential_returned') {
          this.ngZone.run(() => {
            // this.router.navigate(['myapp/somewhere'], { replaceUrl: true });
            console.log('Welcome back!');
          });
        }
      });
    };
  }
  handleCredentialResponse(response) {
    let decodedToken: any | null = null;
    try {
      decodedToken = JSON.parse(atob(response?.credential.split('.')[1]));
    } catch (e) {
      console.error('Error while trying to decode token', e);
    }
    console.log('decodedToken', decodedToken);
  }
  loginButtonGoogle() {
    google.accounts.id.initialize({
      client_id: environment.oauth.google,
      callback: this.handleCredentialResponse.bind(this),
    });
    google.accounts.id.renderButton(
      document.getElementById("buttonDiv"),
      { theme: "outline", size: "large" }
    );
    google.accounts.id.prompt();
  }
}