import { Injectable } from '@angular/core';
import crypto from 'crypto-js';
import jwtDecode from 'jwt-decode';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
@Injectable({
  providedIn: 'root'
})

export class StorageService {

  key(text) {
    var position;
    var length = 10;
    if (text.length % 2 == 1) {
      position = text.length / 2;
    } else {
      position = text.length / 2 - 1;
    }
    return this.encryptDataKey(new Date(), text.substring(position, position + length))
  }
  async getuser() {
    const key = 'a_id'
    return await SecureStoragePlugin.get({ key })
      .then(async value => {
        const res = await this.decryptionAES(value.value)
        if (typeof res === "string") {
          return await jwtDecode(res);
        }
      })
      .catch(() => {
        return 'false';
      });
  }
  async geta(decode = true) {
    const key = 'a_id'
    return await SecureStoragePlugin.get({ key })
      .then(async value => {
        const res = await this.decryptionAES(value.value)
        if (typeof res === "string") {
          if (decode) {
            return String(await jwtDecode(res));
          }
          else {
            return String(res);
          }
        }
      })
      .catch(error => {
        return 'false';
      });
  }
  async getStroage(val: string) {
    const key = val
    return await SecureStoragePlugin.get({ key })
      .then(async value => {
        const res = await this.decryptionAES(value.value)
        if (typeof res === "string") {
          return String(res);
        }
      })
      .catch(error => {
        return 'false';
      });
  }
  async getb(decode = true) {
    const key = 'h_id'
    return await SecureStoragePlugin.get({ key })
      .then(async value => {
        const res = await this.decryptionAES(value.value)
        if (typeof res === "string") {
          if (decode) {
            return String(await jwtDecode(res));
          }
          else {
            return String(res);
          }
        }
      })
      .catch(error => {
        return 'false';
      });
  }
  async encryptData(msg) {
    const key = 's_id'
    return await SecureStoragePlugin.get({ key })
      .then(async value => {
        let _key = crypto.enc.Utf8.parse(value.value);
        let _iv = crypto.enc.Utf8.parse(value.value);
        let encrypted = crypto.AES.encrypt(
          JSON.stringify(msg), _key, {
          keySize: 3,
          iv: _iv,
          mode: crypto.mode.ECB,
          padding: crypto.pad.Pkcs7
        });
        return encrypted.toString();
      })
      .catch(error => {
        return false;
      });
  }

  async decryptionAES(msg: string) {
    const key = 's_id'
    return await SecureStoragePlugin.get({ key })
      .then(async value => {
        let _key = crypto.enc.Utf8.parse(value.value);
        let _iv = crypto.enc.Utf8.parse(value.value);
        let decrypted = crypto.AES.decrypt(
          msg, _key, {
          keySize: 3,
          iv: _iv,
          mode: crypto.mode.ECB,
          padding: crypto.pad.Pkcs7
        }).toString(crypto.enc.Utf8);
        return decrypted;
      })
      .catch(error => {
        return false;
      });
  }
  encryptDataKey(msg: any, _keys: string = '') {
    let _key = crypto.enc.Utf8.parse(_keys);
    let _iv = crypto.enc.Utf8.parse(_keys);
    let encrypted = crypto.AES.encrypt(
      JSON.stringify(msg), _key, {
      keySize: 3,
      iv: _iv,
      mode: crypto.mode.ECB,
      padding: crypto.pad.Pkcs7
    });
    return encrypted.toString();
  }
}