import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { IonRouterOutlet, IonicModule } from '@ionic/angular';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AwesomeCordovaNativePlugin } from '@awesome-cordova-plugins/core';
import { AlertController, IonicRouteStrategy } from '@ionic/angular';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { environment } from 'environments/environment';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
register();
const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

@NgModule({
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    declarations: [
        AppComponent,
    ],
    imports: [
        FormsModule,
        SocialLoginModule,
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot(),
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),
        MultiSelectModule,
        // Core module of your application
        CoreModule,
        // Layout module of your application
        LayoutModule,

        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        AwesomeCordovaNativePlugin,
        AlertController,
        IonRouterOutlet,
        {
            provide: [
                AndroidPermissions
            ],
            useClass: IonicRouteStrategy
        }
    ],
    bootstrap: [
        AppComponent
    ],

})
export class AppModule {
}
