import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { StorageService } from 'app/core/auth/storage.service';
@Injectable({
  providedIn: 'root',
})

export class ApiService {
  public hostname: string;
  public mqtt: any;
  public fileName = '';
  public response: any;
  // public userData: any;

  public token = new BehaviorSubject<any>({});
  newtoken = this.token.asObservable();

  public userData = new BehaviorSubject<any>({});
  userDatas = this.userData.asObservable();

  constructor(
    public http: HttpClient,
    public router: Router,
    public sss: StorageService,
  ) {
    this.hostname = environment.baseurl + environment.path;
  }
  private async checkToken(header = true) {
    let HeaderRequest;
    if (header) {
      HeaderRequest = {
        headers: {
          'Authorization': (await this.sss.geta(false)).replace(/"/g, ""),
          'ClientSecret': (await this.sss.getb(false)).replace(/"/g, ""),
        }
      };
    }
    else {
      HeaderRequest = false
    }
    return HeaderRequest;
  }
  async post(url: string, formData: any, header = true) {
    if (url === 'authadmin') {
      return axios.post(this.hostname + url, formData
      ).then(response => {
        return this.checkResponse(response.data)
      }).catch(error => {
        if (error.code == 'ERR_NETWORK') {
          this.noInternet()
        }
        else {
          return this.checkResponse(error.response.data)
        }
      });
    }
    else {
      return axios.post(this.hostname + url, formData,
        await this.checkToken(header)
      ).then(response => {
        return this.checkResponse(response.data)
      }).catch(error => {
        if (error.code == 'ERR_NETWORK') {
          this.noInternet()
        }
        else {
          return this.checkResponse(error.response.data)
        }
      });
    }
  }
  async put(url: string, formData: any, header = true) {
    return axios.put(this.hostname + url, formData,
      await this.checkToken(header)
    ).then(response => {
      return response.data
    }).catch(error => {
      if (error.code == 'ERR_NETWORK') {
        this.noInternet()
      }
      else {
        return this.checkResponse(error.response.data)
      }
    });
  }
  async postFile(url: string, file: File, header = true) {
    try {
      this.fileName = file.name;
      const formData = new FormData();
      formData.append('file', file);
      const upload = await this.http.post(this.hostname + url, formData, await this.checkToken(header))
        .pipe(map((response) => response)).toPromise();
      return this.checkResponse(upload);
    } catch (err) {
      return err;
    }
  }
  async get(url: string, header = true) {
    return axios.get(this.hostname + url,
      await this.checkToken(header)
    ).then(response => {
      return this.checkResponse(response.data)
    }).catch(error => {
      if (error.code === 'ERR_NETWORK') {
        this.noInternet()
      }
      else {
        return this.checkResponse(error.response.data)
      }
    });
  }
  getBaseq64ImageFromUrl(url: string) {
    const src = url

    const toDataURL = url => fetch(url, {
      // mode: "no-cors",
      // mode: "no-cors",
      // method: 'GET',
      headers: {
        // "Content-Type": "application/json",
        "access-control-allow-credentials": "true",
        // "access-control-allow-methods": "GET, POST, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Origin": "http://localhost:4200",
        // "accept-ranges": "bytes",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
    })
      .then(response => response.blob())
      .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsDataURL(blob)
      }))
    toDataURL(src)
      .then(dataUrl => {
        // console.log(dataUrl)
      })
  }
  async getImage(url: string, header = true) {
    try {
      const i = await this.http.get<any>(url, await this.checkToken(header))
        .pipe(map((response) => response)).toPromise();
      return this.checkResponse(i);
    } catch (err) {
      return err;
    }
  }
  async getAllById(url: string, colloum: string, id: string, header = true) {
    return axios.get(this.hostname + colloum + '/' + url + '/' + id,
      await this.checkToken(header)
    ).then(response => {
      return response.data
    }).catch(error => {
      if (error.code == 'ERR_NETWORK') {
        this.noInternet()
      }
      else {
        return this.checkResponse(error.response.data)
      }
    });
  }

  async getById(url: string, id: string, header = true) {
    return axios.get(this.hostname + url + id,
      await this.checkToken(header)
    ).then(response => {
      return response.data
    }).catch(error => {
      if (error.code == 'ERR_NETWORK') {
        this.noInternet()
      }
      else {
        return this.checkResponse(error.response.data)
      }
    });
  }

  async deleteById(url: string, id: string, header = true) {
    return axios.delete(this.hostname + url + '/' + id,
      await this.checkToken(header)
    ).then(response => {
      return response.data
    }).catch(error => {
      if (error.code == 'ERR_NETWORK') {
        this.noInternet()
      }
      else {
        return this.checkResponse(error.response.data)
      }
    });
  }
  checkResponse(response: any) {
    // console.log(response)
    if (response.message == 'Token Expired' && response.success === false) {
      this.router.navigate(['/sign-out'], { replaceUrl: true });
    }
    else if (response.success === false) {
      response.message = false;
      return response
    }
    else {
      return response
    }
  }
  noInternet() {
    // this.router.navigate(['/no-internet'], { replaceUrl: true }).then(data => {
    //   window.location.reload()
    // });
  }
  public signOut() {
    localStorage.clear();
    // this.router.navigate(['/auth/login'], { replaceUrl: true });
    this.router.navigate(['/dashboard/default'], { replaceUrl: true });
  }
  async isLoggedIn() {
    const decode = this.sss.getuser();
    // console.log(decode);
    return decode;
  }
}
