import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from 'app/core/auth/auth.service';
import { BioAuth } from 'app/shared/bio-auth';

const admin = '/dashboards/project';
const peserta = '/dashboards/project';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard {
    /**
     * Constructor
     */
    constructor(
        private _authService: AuthService,
        private _router: Router,
        private bio: BioAuth
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Can match
     *
     * @param route
     * @param segments
     */
    async canMatch(state: ActivatedRouteSnapshot, segments: UrlSegment[]) {
        return await this._check(segments, state);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Check the authenticated status
     *
     * @param segments
     * @private
     */
    private async _check(segments: UrlSegment[], state: ActivatedRouteSnapshot) {
        // Check the authentication status
        let match: any;
        const role = await this._authService.signInUsingToken()
        const redirectURL = `/${segments.join('/')}`;

        const urlTree = this._router.parseUrl(`sign-in?redirectURL=${redirectURL}`);
        if (!role) {
            await this._router.navigateByUrl('sign-in');
        }
        else {
            if (redirectURL == '/') {
                match = await state.data.role.find(function (element: any) {
                    return element == role;
                });
                if (match) {
                    await this._router.navigateByUrl(peserta);
                }
            }
            else {
                match = await state.data.role.find(function (element: any) {
                    return element == role;
                });
            }
        }

        if (!match) {
            await this._router.navigateByUrl(urlTree).then(async () => {
                localStorage.clear()
                const res = await this.bio.deleteauth()
                if (res) {
                    window.location.reload();
                }
            })
        }
    }
}
