import { AvailableResult, BiometryType, NativeBiometric } from "capacitor-native-biometric";
import { Platform, AlertController } from '@ionic/angular';
import { environment } from "environments/environment";
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { AuthService } from 'app/core/auth/auth.service';
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root',
})

export class BioAuth {
  constructor(
    private platform: Platform,
    private _router: Router,
    private authService: AuthService,
  ) { }
  async performBiometricVerificatin() {
    if (this.platform.is('android') && this.platform.is('capacitor')) {
      const result = await NativeBiometric.isAvailable();
      if (!result.isAvailable) return false;
      return await this.platform.ready().then(async () => {
        return await this.isandroid(result);
      });
    }
    else if (this.platform.is('ios') && this.platform.is('capacitor')) {
      const result = await NativeBiometric.isAvailable();
      if (!result.isAvailable) return false;
      return await this.platform.ready().then(async () => {
        return await this.isios(result);
      });
    }
    else {
      this.platform.ready().then(() => {
        return false;
      });
    }
    return false;
  }
  async initdevice() {
    const SU_ID = { 'key': 'SU_ID' };
    const SA_ID = { 'key': 'SA_ID' };
    const username = (await SecureStoragePlugin.get(SU_ID)).value
    const password = (await SecureStoragePlugin.get(SA_ID)).value
    await NativeBiometric.setCredentials({
      username: username,
      password: password,
      server: environment.baseurl,
    }).then();
  }
  private async isios(result: AvailableResult) {
    return result.biometryType == BiometryType.TOUCH_ID ? true : false;
  }
  private async isandroid(result: AvailableResult) {
    if (result.biometryType == BiometryType.FINGERPRINT || result.biometryType == BiometryType.MULTIPLE) {
      return true;
    }
    else {
      return false
    }
    // const FACE_AUTHENTICATION = result.biometryType == BiometryType.FACE_AUTHENTICATION;
    // return result.biometryType == BiometryType.FINGERPRINT;
    // const IRIS_AUTHENTICATION = result.biometryType == BiometryType.IRIS_AUTHENTICATION;
  }

  async authwith() {
    const verified = await NativeBiometric.verifyIdentity({
      reason: "Untuk Mempermudah proses login",
      title: "Verifikasi Sidik Jari",
      subtitle: "Login Aplikasi",
      maxAttempts: 5
    })
      .then(() => true)
      .catch(() => false);
    if (!verified) return;
    else {
      const f = await this.getuser()
      const t = await this.authService.signIn(f)
      if (t) {
        this._router.navigate(['dashboards/project'], { replaceUrl: true, })
      }
    }
  }
  async deleteauth() {
    return await NativeBiometric.deleteCredentials({
      server: environment.baseurl
    }).then(() => { return true })
      .catch(() => { return false });
  }
  async getauth() {
    const g = await NativeBiometric.getCredentials({
      server: environment.baseurl,
    }).then(() => true)
      .catch(() => false);
    return g
  }
  async getuser() {
    return await NativeBiometric.getCredentials({
      server: environment.baseurl,
    })
  }
}