import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { UserService } from 'app/core/user/user.service';
import { ApiService } from 'app/shared/api.service';
import { environment } from 'environments/environment';
import jwtDecode from 'jwt-decode';
import { StorageService } from './storage.service';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { LoadingController } from '@ionic/angular';
@Injectable()
export class AuthService {
    private _authenticated: boolean = false;
    private _role = undefined
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        private api: ApiService,
        private key: StorageService,
        private loadingCtrl: LoadingController,
    ) {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for access token
     */
    get accessToken(): string {
        if (localStorage.getItem('token')) {
            return JSON.parse(localStorage.getItem('token'))
        }
        return '';
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post('api/auth/forgot-password', email);
    }
    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post('api/auth/reset-password', password);
    }
    /**
     * Sign in
     *
     * @param credentials
     */
    async signIn(credentials: { username: string; password: string }) {
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }
        this.showLoading()
        const formData = new FormData();
        formData.append('username', credentials.username);
        formData.append('password', credentials.password);
        const responsee2 = await this.api.post(environment.auth, formData);
        if (responsee2.success) {
            const decode = jwtDecode(responsee2.message.cnc_token);
            const keys = this.key.key(JSON.stringify(responsee2.message.Authorization))
            const s_id = { 'key': 's_id', 'value': keys };
            await SecureStoragePlugin.set(s_id)
            const a_idv = await this.key.encryptData(responsee2.message.Authorization)
            if (typeof a_idv === "string") {
                const a_id = { 'key': 'a_id', 'value': a_idv };
                await SecureStoragePlugin.set(a_id)
            }
            const h_idv = await this.key.encryptData(responsee2.message.ClientSecret)
            if (typeof h_idv === "string") {
                const h_id = { 'key': 'h_id', 'value': h_idv };
                await SecureStoragePlugin.set(h_id)
            }

            const token = { 'key': 'token', 'value': responsee2.message.cnc_token };
            const key = { 'key': 'key', 'value': responsee2.message.cnc_key };
            const SU_ID = { 'key': 'SU_ID', 'value': credentials.username };
            const SA_ID = { 'key': 'SA_ID', 'value': credentials.password };

            await SecureStoragePlugin.set(token)
            await SecureStoragePlugin.set(key)

            await SecureStoragePlugin.set(SU_ID)
            await SecureStoragePlugin.set(SA_ID)
            // const avatar = await this.getfoto();
            // if (avatar.success) {
            //     localStorage.setItem('avatar', avatar.message);
            //     decode['avatar'] = avatar.message
            // }
            this._authenticated = true;
            this._role = decode['super_group_id'];
            const us = {
                id: decode['iduser'],
                name: decode['nama'],
                email: decode['nama'],
                status: 'login',
            }
            this._userService.user = us;
            this.dismissLoading()
            return decode;
        }
    }
    async showLoading() {
        const loading = await this.loadingCtrl.create({
            message: 'Mohon Tunggu',
        });
        loading.present();
    }

    async dismissLoading() {
        this.loadingCtrl.dismiss();
    }
    private async getfoto() {
        return await this.api.get('getfoto', true);
    }
    private as
    /**
     * Sign in using the access token
     */
    async signInUsingToken() {
        const username = await this.key.geta(false)
        if (username != 'false') {
            const token = await jwtDecode(username);
            const us = {
                id: token['iduser'],
                name: token['nama'],
                email: token['nama'],
                status: 'login',
            }
            this._userService.user = us;
            this._authenticated = true;
            this._role = token['super_group_id'];
            return this._role;
        }
        return false;
    }

    /**
     * Sign out
     */
    async signOut(){
        const SU_ID = { 'key': 'SU_ID' };
        const SA_ID = { 'key': 'SA_ID' };
        const username = await SecureStoragePlugin.get(SU_ID)
            .then(async value => {
                return value.value
            })
        const password = await SecureStoragePlugin.get(SA_ID)
            .then(async value => {
                return value.value
            })
        localStorage.clear();
        const ESU_ID = { 'key': 'SU_ID', 'value': username };
        const ESA_ID = { 'key': 'SA_ID', 'value': password };

        await SecureStoragePlugin.set(ESU_ID)
        await SecureStoragePlugin.set(ESA_ID)
        // Set the authenticated flag to false
        this._authenticated = false;

        // Return the observable
        return true;
    }

    /**
     * Sign up
     *
     * @param user
     */
    async signUp(user: { name: string; email: string; password: string; company: string }) {
        const formData = new FormData();
        formData.append('username', user.name);
        formData.append('email', user.email);
        formData.append('password', user.password);
        return await this.api.post(environment.register, formData);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: { email: string; password: string }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    async check(): Promise<Observable<any>> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!this.accessToken) {
            return of(false);
        }
        if (this._role) {
            return of(this._role);
        }
        // Check the access token expire date
        // if (AuthUtils.isTokenExpired(this.accessToken)) {
        //     return of(false);
        // }

        // If the access token exists and it didn't expire, sign in using it
        return await this.signInUsingToken();
    }
    async checkRole(): Promise<Observable<any>> {
        // Check if the user is logged in
        if (this._role) {
            return of(this._role);
        }
        // Check the access token expire date
        // if (AuthUtils.isTokenExpired(this.accessToken)) {
        //     return of(false);
        // }

        // If the access token exists and it didn't expire, sign in using it
        return await this.signInUsingToken();
    }
}
