export const environment = {
  production: false,
  baseurl: 'http://localhost/api-Laundry/',
  pathImg: 'http://localhost/api-Laundry/image/',
  path: '',
  auth: 'auth',
  register: 'register',
  token:'ZXJwLmF3aC5jby5pZA==',
  oauth: {
    google: '714559658090-2j27v8difnnq9k6utqougublsn18fn53.apps.googleusercontent.com'
  }
};
