import { Component, OnInit } from '@angular/core';
import DisableDevtool from 'disable-devtool';
import { Platform, AlertController } from '@ionic/angular';
import { Location } from '@angular/common';
import { App } from '@capacitor/app';
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { environment } from 'environments/environment';
import { GoogleAuth } from './shared/google-auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  /**
   * Constructor
   */
  loggedIn: boolean;
  accessToken
  constructor(
    private googleauth: GoogleAuth,
    public platform: Platform,
    private location: Location,
    public alertController: AlertController,
  ) {
    // const fpPromise = FingerprintJS.load({
    //   monitoring: false
    // })
    //   ; (async () => {
    //     // Get the visitor identifier when you need it.
    //     const fp = await fpPromise
    //     const result = await fp.get()
    //     console.log('fingerprint', { 'visitor id': result.visitorId, 'confidence': result.confidence.score })
    //   })()
    // this.authService.authState.subscribe((user) => {
    //   this.user = user;
    //   console.log(user)
    //   this.loggedIn = (user != null);
    // });
    // this.getAccessToken()
    this.googleauth.oneTap()
    if (this.platform.is('android')) {
      this.platform.ready().then(() => {
        this.initializeApp();
        // DisableDevtool({
        //     ondevtoolopen(type, next){
        //         next();
        //     }
        // });}
      });
    }
    else if (this.platform.is('ios')) {
      this.platform.ready().then(() => {
        this.initializeApp();
        // DisableDevtool({
        //     ondevtoolopen(type, next){
        //         next();
        //     }
        // });}
      });
    }
    else {
      this.platform.ready().then(() => {
        // this.initializeApp()
        // DisableDevtool({
        //     ondevtoolopen(type, next){
        //         next();
        //     }
        // });}
      });
    }
  }

  initializeApp() {
    App.addListener('backButton', async () => {
      let route = this.location.path();
      if (route.includes("sign-in") || route.includes("home")) {
        await this.showExitConfirm();
      }
      else {
        this.location.back();
      }
    });
  }
  async showExitConfirm() {
    const alert = await this.alertController.create({
      header: 'App termination',
      message: 'Do you want to close the app?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
          }
        },
        {
          text: 'Exit',
          handler: () => {
            App.exitApp();
          }
        }
      ]
    });
    await alert.present();
  }
}
